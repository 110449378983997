import { Amplify } from 'aws-amplify';
import './App.css';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

function App({ signOut, user }) {
  return (
      <div>
          <div>
              <button className="signOutButton" onClick={signOut}>Sign Out</button>
        </div>
    <div class="banner">
        <h1>Clubbl App</h1>
    </div>
    <div class="courseName">
        <label for="courseName">Golf Course Name:</label>
        <select class="courseSelect" id="courseName" required>
            <option value="">Select Course</option>
            <option value="Oake Manor">Oake Manor</option>
            <option value="Vivary Park">Vivary Park</option>
            <option value="Augusta National">Augusta National</option>
        </select>
    </div>
<form id="golfForm">

    <h2>Enter Scores and Clubs for Each Hole:</h2>
    <table>
        <thead>
        <tr>
            <th>Hole</th>
            <th>Score</th>
            <th>Putts</th>
            <th>Club Used</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>1</td>
            <td><input type="number" id="score1" min="1" max="10" required></input></td>
            <td><input type="number" id="putt1" min="1" max="10" required></input></td>
            <td>
                <select id="club1" required>
                    <option value="">Select Club</option>
                    <option value="Driver">Driver</option>
                    <option value="Iron">Iron</option>
                    <option value="Wedge">Wedge</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>2</td>
            <td><input type="number" id="score2" min="1" max="10" required></input></td>
            <td><input type="number" id="putt2" min="1" max="10" required></input></td>
            <td>
                <select id="club2" required>
                    <option value="">Select Club</option>
                    <option value="Driver">Driver</option>
                    <option value="Iron">Iron</option>
                    <option value="Wedge">Wedge</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>3</td>
            <td><input type="number" id="score3" min="1" max="10" required></input></td>
            <td><input type="number" id="putt3" min="1" max="10" required></input></td>
            <td>
                <select id="club3" required>
                    <option value="">Select Club</option>
                    <option value="Driver">Driver</option>
                    <option value="Iron">Iron</option>
                    <option value="Wedge">Wedge</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>16</td>
            <td><input type="number" id="score16" min="1" max="10" required></input></td>
            <td><input type="number" id="putt16" min="1" max="10" required></input></td>
            <td>
                <select id="club16" required>
                    <option value="">Select Club</option>
                    <option value="Driver">Driver</option>
                    <option value="Iron">Iron</option>
                    <option value="Wedge">Wedge</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>17</td>
            <td><input type="number" id="score17" min="1" max="10" required></input></td>
            <td><input type="number" id="putt17" min="1" max="10" required></input></td>
            <td>
                <select id="club17" required>
                    <option value="">Select Club</option>
                    <option value="Driver">Driver</option>
                    <option value="Iron">Iron</option>
                    <option value="Wedge">Wedge</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>18</td>
            <td><input type="number" id="score18" min="1" max="10" required></input></td>
            <td><input type="number" id="putt18" min="1" max="10" required></input></td>
            <td>
                <select id="club18" required>
                    <option value="">Select Club</option>
                    <option value="Driver">Driver</option>
                    <option value="Iron">Iron</option>
                    <option value="Wedge">Wedge</option>
                </select>
            </td>
        </tr>
        </tbody>
    </table>

    <button type="button" onclick="submitData()">Submit Scores and Clubs</button>
</form>
</div>
  );
}

export default withAuthenticator(App);